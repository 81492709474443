var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"lRaHIHb5HR1FrrzPdVHvn"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

/**
 * Sentry is disabled in client side. Only server side error will be reported
 */

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN || null,
  tracesSampleRate: 0.05,
  environment: process.env.APP_ENV,
  enabled: true,
});
